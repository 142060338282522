* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter";
  background: #060d1b;
}
.body_opacity {
  position: relative;
  width: 75%;
  float: left;
  border-left: 1px solid #323e4e;
  min-height: 100vh;
  opacity: 0.6;
}

button {
  cursor: pointer;
}

input:focus {
  border: 1px solid #273148;
  caret-color: white;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #233f78;
}
body::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
body::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #6080bc;
  border-radius: 10px;
}
/* / Chrome, Safari, Edge, Opera / */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* / Firefox / */
input[type="number"] {
  -moz-appearance: textfield;
}
.sidebar_content {
  width: 100%;
  float: left;
}
.sidebar_content ul {
  width: 100%;
  float: left;
  margin-top: 14px;
}
.sidebar_content ul a {
  text-decoration: none;
}

.sidebar_parent {
  height: 100%;
  width: 25%;
  float: left;
  background: #060d1b;
}

.sidebar_parent .logo {
  margin: 57px 57px 0px 57px;
}

.sidebar_parent .side_menu {
  width: 100%;
  float: left;
  cursor: pointer;
  padding: 14px 0 14px 30px;
}
.side_menu .list.list_active .span_text {
  color: #ffffff;
}

.sidebar_parent .span_icon {
  width: 10%;
  float: left;
  color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar_parent .span_text {
  width: 90%;
  float: left;
  color: #ffffff6b;
  text-align: left;
  padding: 0 0 0 10px;
}

.sidebar_parent a {
  text-decoration: none;
  color: #ffffff6b;
  padding: 0 0px 0 10px;
}

.sidebar_parent span.span_text.arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 27px 0 10px;
}

.side_menu .list {
  display: flex;
  align-items: center;
}

/* For Active */

.sidebar_parent .side_menu .list.active {
  background: #3373ef;
  border-radius: 8px;
  width: 99%;
  float: left;
  padding: 15px 0 15px 13px;
  margin: -15px 0 -11px -11px;
}

.sidebar_parent .side_menu .list.active .span_text.arrow {
  padding: 0 14px 0 0;
}
.sidebar_parent .side_menu .list.active .span_text a {
  color: white;
}

.sidebar_parent .side_menu .list.active .span_text {
  color: white;
}
.sidebar_parent .side_menu .list.active .span_icon_arrow i {
  color: white;
}
.sidebar_parent .side_menu .list.active .span_icon svg path,
.sidebar_parent .side_menu .list.active .span_icon svg circle {
  fill-opacity: 1;
  fill: #fff;
}

.sidebar_parent .side_menu .list.active {
  display: flex;
  align-items: center;
}

/* ACCORDIAN  JQUERY*/

.panel {
  position: relative;
  width: 85%;
  float: left;
  cursor: pointer;
  margin: 0px 0px 0px 41px;
  padding: 25px 0 0 0;
  color: #ffffff6b;
  display: none;
}

.panel .sss {
  padding: unset;
}
.sss::before {
  content: "";
  position: absolute;
  left: -28px;
  top: 83%;
  width: 4%;
  height: 2px;
  background-color: #ffffff6b;
}

.panel:before {
  content: "";
  height: 45px;
  background: #ffffff6b;
  position: absolute;
  top: -13%;
  left: -30px;
  width: 2px;
}
.active .sss::before {
  content: "";
  position: absolute;
  left: -28px;
  top: 83%;
  width: 4%;
  height: 2px;
  background-color: white;
}
.panel.active:before {
  content: "";
  height: 89px;
  background: white;
  position: absolute;
  top: -109%;
  left: -30px;
  width: 3px;
}
.panel:nth-child(2).active:before {
  content: "";
  height: 41px;
  background: white;
  position: absolute;
  top: -22%;
  left: -30px;
  bottom: 149%;
  width: 3px;
  z-index: 2;
  margin: 8px 0 0 0;
}

.active.panel a {
  color: white;
}

.body {
  position: relative;
}

.body .navbar,
.body_opacity .navbar {
  border-bottom: 1px solid #222a35;
  width: 100%;
  height: 15vh;
  border-bottom: 1px solid #323e4e;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard_heading {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #ffffff;
  position: absolute;
  padding: 2.5rem 0 2.5rem 0;
}
.dashimg {
  position: static;
  margin: -35px 50.5879px;
}
.dash {
  width: 260px;
  height: 50px;
  left: 2%;
  top: 13%;
  background: #3373ef;
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding: 7px;
}

.dropdown li {
  text-decoration: none;
  list-style: none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: rgba(255, 255, 255, 0.5);
  flex: none;
  padding: 8px;
}
.downbtn {
  width: 156px;
  height: 48px;
  background: #161e2f;
  border-radius: 10px;
  color: #ffffff;
  margin-top: 35px;
  padding: 3px 10px 0 0;
  outline: none;
  border: none;
  position: absolute;
  right: 300px;
}

#nav {
  position: relative;
}
#nav a {
  padding: 5px 0px 5px;
  display: flex;
  align-items: center;
}
.dropdown img {
  width: 24%;
  float: left;
  padding: 0 6px 0 2px;
}
.dropdown-toggle {
  padding: 0;
}
/**** ****/
ul.dropdowns {
  display: none;
  position: absolute;
  margin-top: 5px;
  padding: 5px 5px 0 0;
  background: #161e2f;
  width: 100%;
}
ul.dropdowns li {
  list-style-type: none;
}
ul.dropdowns li a {
  text-decoration: none;
  padding: 0em 1em;
  display: block;
}
.prof {
  width: 35px;
  /* margin-top: 7px; */
}
a {
  color: white;
  text-decoration: none;
}
.profname {
  position: relative;
  top: 1px;
  bottom: 10px;
  padding: 10px;
}
.arrow {
  position: relative;
}
.dropdown-toggle .span_icon_arrow {
  position: absolute;
  top: 8px;
  right: 10px;
}
.dropdown {
  display: none;
  position: absolute;
  left: 0;
  top: 42px;
  /* background: #161e2f; */
  background: rgb(31, 39, 55);
  width: 100%;
  border-radius: 10px;
}

/* body */

.body {
  width: 75%;
  float: left;
  border-left: 1px solid #323e4e;
  min-height: 100vh;
}

.body .container_stat {
  width: 95%;
  margin: auto;
  /* float: left; */
  background: #060d1b;
  height: auto;
}
.body .date_time_update {
  width: 100%;
  height: 50px;
  float: left;
  /* background-color: aqua; */
  float: right;
  text-align: end;
  color: #fff;
  padding: 20px 20px 20px 0;
  /* margin: 20px 0 0 0; */
}
.body .date_time_update h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 10px;
}

.body .date_time_update span {
  color: #ffffff6b;
}
.body .stat_parent {
  width: 100%;
  float: left;
}
.body .stat {
  width: 50%;
  padding: 10px;
}

.stat_head_cards_parent {
  width: 100%;
  float: left;
}

.stat_body_cards {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
}
.stat_head_cards {
  width: 50%;
  float: left;
  padding: 10px;
}
.stat_head_cards .head {
  color: rgba(249, 249, 249, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 0 12px 10px;
}

.body .stat_content {
  width: 100%;
  float: left;
  height: auto;
  padding: 0 0 23px 0;
}
.body .stat_head {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
}
.body .stat_card {
  width: 32%;
  float: left;
  padding: 15px 12px 15px 0;
  background: #161e2f;
  border-radius: 10px;
}

.body .stat_body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  float: left;
}
.body .stat_graph {
  /* border: 1px solid white; */
  width: 100%;
  height: auto;
  display: block;
  float: left;
  padding: 10px;

  margin-top: 20px;
  background: #161e2f;
  border-radius: 10px;
  padding: 20px 20px 30px 20px;
}

.body .card_content {
  width: 85%;
  margin: auto;
  height: 100%;
}
/* .body .card_icon {
  width: 40%;
  height: 5vh;
  background: #060d1b;
  padding: 10px;
  border-radius: 5px;
} */

.body .card_icon {
  background: #060d1b;
  border-radius: 5px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body .card_icon img {
  width: auto;
  height: auto;
}
.body .stat_text {
  padding: 10px 0 25px 0;
  position: relative;
}

.body .stat_text h4 {
  color: rgba(249, 249, 249, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  padding: 5px 0 5px 0;
}

.body .stat_text h3 {
  color: rgba(249, 249, 249, 0.8);
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 2px 0 12px 0;
}

.body .stat_text h6 {
  color: rgba(200, 200, 200, 1);
  font-size: 10px;
  font-style: normal;
  font-weight: 100;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  padding: 2px 0 0 0;
  position: absolute;
}

.body .stat_text span {
  color: #2ecc71;
  font-size: 11px;
}

.body .stat_body h4 {
  color: rgba(200, 200, 200, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.body .stat_body h6 {
  color: rgba(200, 200, 200, 1);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 100;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 0 10px 0;
}

/******************************************* Customer Management ****************************************************************/

.customer_table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.customer_table tr td:last-child {
  overflow: unset;
}
.customer_table tr:last-child .option_table,
.customer_table tr:nth-last-child(2) .option_table,
.customer_table tr:nth-last-child(3) .option_table {
  position: absolute;
  width: 165px;
  height: 149px;
  top: -120px;
  background: #1f2737;
  border-radius: 10px;
  right: 48px;
  z-index: 10;
}
.customer_table th,
td {
  padding: 8px;
}
.customer_table th:first-child {
  padding: 20px 22px 20px 35px;
  /* width: 48%; */
}
.customer_table td {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
}
.customer_table td:first-child {
  padding: 20px 22px 20px 35px;
}
.customer_table .th {
  background-color: #1f2737;
}
.customer_table th {
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  opacity: 0.6;
}
.customer_table tr {
  border-bottom: 1px solid #222a35;
}
.customer_table {
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  width: 95%;
  background-color: #141b29;
  min-height: 500px;
}
.customer_table thead tr {
  background-color: #1f2737;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  border: none;
}
.customer_table td,
.customer_table th {
  padding: 20px 20px;
}
.customer_table table tr:nth-child(2) .option_table,
.customer_table table tr:nth-child(3) .option_table,
.customer_table table tr:nth-child(4) .option_table {
  position: absolute;
  width: 165px;
  height: 149px;
  top: 43px;
  background: #1f2737;
  border-radius: 10px;
  right: 48px;
  z-index: 10;

  /* position: absolute;
    width: 165px;
    height: 149px;
    top: 45px;
    background: #1f2737;
    border-radius: 10px;
    right: 30px;
    z-index: 10; */
}
.customer_table .Inprogress {
  color: #559bff;
}
.customer_table .Incomplete {
  color: #ff8836;
}
.customer_table .inactive {
  color: rgba(255, 255, 255, 0.3);
}
.customer_table .complete {
  color: #2ecc71;
}
.customer_page .pagination {
  display: inline-block;
}
.customer_page .pagination a {
  color: #999999;
  float: left;
  padding: 8px 15px;
  text-decoration: none;
  background-color: #1f2737;
  border-radius: 5px;
  margin: 1rem 0rem 1rem 1rem;
}
.customer_page .pagination a.active {
  color: #ffffff;
  border-radius: 5px;
}
.customer_page .result p {
  color: #999;
  margin: 1rem 0 1rem 1rem;
}
/********pagination******/
.customer_page {
  display: flex;
  justify-content: space-between;
  margin: auto;
  /* padding-bottom: 2rem; */
  width: 95%;
}
.pagination_result_active {
  color: white;
}

.customer_page .result {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customer_page .pagination a:first-child {
  background-color: #1f2737;
  border-radius: 5px;
  color: #999;
  float: left;
  margin: 1rem 0 0 0;
  padding: 8px 15px;
  text-decoration: none;
}

.customer_table .bars {
  position: relative;
  left: 10px;
  top: 2px;
  color: #ffffff;
  font-weight: 400;
  font-size: 22px;
}
.customer_table .inactive_bars {
  position: relative;
  left: 5px;
  top: 2px;
  font-weight: 400;
  font-size: 22px;
  color: #ffffff;
}
.customer_table .dot {
  font-size: 9px;
  position: relative;
  color: #2ecc71;
  top: -2px;
  left: -5px;
}
.customer_table .inactive_dot {
  font-size: 9px;
  position: relative;
  color: #5b5f69;
  top: -2px;
  left: -5px;
}
.customer_table .inactive_alert {
  font-size: 9px;
  position: relative;
  color: #5b5f69;
  top: -2px;
  left: -3px;
}
.customer_content .dash_input::-webkit-input-placeholder {
  font-size: 14px;
}
/* Navbar */

.nav_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}
.nav_content .both_button {
  display: flex;
  margin-left: 1rem;
}
.nav_content h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
}

.nav_content .dropdown {
  display: none;
  position: absolute;
  left: 16px;
  top: 42px;
  /* background: #161e2f; */
  background: rgb(31, 39, 55);
  width: 92%;
  border-radius: 0 0px 10px 10px;
  z-index: 1;
}
.nav_content .dropdown li {
  text-decoration: none;
  list-style: none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  flex: none;
  padding: 8px;
}
.nav_content .downloadbtn {
  width: 156px;
  height: 48px;
  background: #161e2f;
  border-radius: 10px;
  color: #ffffff;
  outline: none;
  border: none;
}
.nav_content .downloadbtn img {
  padding-right: 5px;
}

.nav_content .bellbtn {
  width: 50px;
  height: 48px;
  background: #161e2f;
  border-radius: 10px;
  color: #ffffff;
  margin-left: 1rem;
  position: relative;
  outline: none;
  border: none;
  padding: 10px;
}

.nav_content .bell_alert {
  position: absolute;
  font-size: 8px;
  top: 14px;
  left: 25px;
  color: red;
}
.sidebar_parent .span_text .span_icon_arrow i {
  color: #ffffff6b;
}

.customer_content .search_icon i {
  font-weight: 500;
}
.nav_content .bell_icon {
  font-size: 15px;
}
.nav_content .profile_dropdown {
  width: 190px;
  height: 48px;
  background: #161e2f;
  border-radius: 10px;
  color: #ffffff;
  padding: 7px;
  margin-left: 1rem;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
}

.nav_content #profile {
  position: relative;
}
.nav_content #profile a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav_content .dropdown img {
  width: 24%;
  float: left;
  padding: 0 6px 0 2px;
}
.nav_content .prof {
  width: 35px;
}
.nav_content a {
  color: white;
  text-decoration: none;
}
.nav_content .profname {
  position: relative;
  top: 1px;
  bottom: 10px;
  padding: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
}
.nav_content .dropdown-toggle .span_icon_arrow {
  position: absolute;
  top: 18px;
  right: 20px;
}

/* working on Reltime project

.option_table {
  position: absolute;
  width: 165px;
  height: 149px;
  top: 43px;
  background: #1f2737;
  border-radius: 10px;
  right: 48px;
  z-index: 10;
}

.option_table ul {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding: 0 12px;
  margin: -18px 1px;
}
.option_table li {
  text-decoration: none;
  list-style: none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 8px 0 8px 10px;
}
.option_table li a {
  color: white;
  text-decoration: none;
  list-style: none;
}
.option_table span {
  position: relative;
  bottom: -41px;
  left: -10px;
}

/*********** Filter *********/
.customer_modal {
  position: absolute;
  z-index: 10;
  right: 30px;
  top: 200px;
}

.customer_modal .cards {
  width: 304px;
  /* height: 745px; */
  background: #1f2737;
  border-radius: 10px;
}

.customer_modal .date {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.customer_modal .filter-input {
  width: 82%;
  margin: auto;
  padding-top: 8px;
  height: 8.5rem;
}

.filter_footer {
  width: 80%;
  margin: auto;
}
.customer_modal .lines {
  position: relative;
  border: none;
  height: 1px;
  background: rgba(219, 196, 196, 0.1);
  width: 100%;
}

.customer_modal .bothfield {
  display: flex;
  padding-top: 50px;
  column-gap: 34px;
}

.customer_modal .input1 {
  width: 7rem;
  height: 37px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  background: transparent;
}

.customer_modal label {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 14px;
  color: #ffffff;
}

.customer_modal .rto-Spent {
  width: 82%;
  margin: auto;
  padding-top: 20px;
}

.customer_modal .btns {
  padding-top: 10px;
}

.customer_modal .btns_clr {
  margin-left: 5rem;
}

.customer_modal .rto-Spent li {
  color: #ffffff;
  list-style: none;
  display: inline;
  opacity: 0.3;
  font-style: normal;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 11px 8px 11px 8px;
  border-bottom: 1px solid white;
}

.customer_modal .below {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 20px;
}

.customer_modal .input2 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 133.5%;
  width: 263px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  background: transparent;
  margin-top: 8px;
  color: #ffffff;
  text-align: start;
  padding: 15px;
}

/* .customer_modal .input2 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 133.5%;
  width: 263px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  background: transparent;
  margin-top: 5px;
  color: #ffffff;
  text-align: start;
  padding-left: 1rem;
} */

.customer_modal input,
select,
textarea {
  color: white;
  padding: 10px;
  font-size: 15px;
}

.customer_modal .clr {
  position: absolute;
  width: 86px;
  height: 36px;
  background: transparent;
  border: 1px solid #3373ef;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #3373ef;
}
.customer_modal .slider {
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
}

.customer_modal .slider:hover {
  opacity: 1;
}

.customer_modal .range {
  color: white;
  font-size: 15px;
  width: 100%;
}

.customer_modal li.active-spent {
  border-bottom: 1px solid white;
  opacity: 1;
  padding: 11px 8px 11px 8px;
}
.customer_modal .lines1 {
  border: none;
  height: 1px;
  background: rgba(219, 196, 196, 0.1);
  width: 100%;
  margin: auto;
  margin-top: 10px;
}
/********filter-end******/

/*********customer_content*******/
.customer_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: auto;
  padding: 2rem 0;
}

.customer_content .both_button {
  display: flex;
  margin-left: 1rem;
}

.customer_content h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
}

.customer_content .dash_input {
  width: 300px;
  height: 48px;
  background: #141b29;
  border: 1px solid #273148;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 45px;
  outline: none;
}

.customer_content .search_icon {
  position: relative;
  left: 35px;
  top: 13px;
  font-size: 20px;
  color: #999999;
}

.customer_content .filter_btn {
  position: relative;
  width: 100px;
  height: 48px;
  background: #161e2f;
  border-radius: 10px;
  color: #ffffff;
  margin-left: 1rem;
  outline: none;
  border: none;
  padding: 10px;
}

.customer_content input,
select,
textarea {
  color: white;
  padding: 23px 15px;
  font-size: 15px;
}

.customer_content .filter_icons {
  width: 30%;
  color: #ffffff;
}
.customer_content .filter_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

/**********customer_content-End*******/

.footer_btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  width: 66%;
}

/*customer profile  */
.customer_profile {
  width: 95%;
  margin: auto;
  /* height: 100vh; */
}
.customer_profile .breadcrumb_head {
  width: 100%;
  padding: 15px 0 30px 0;
}
.customer_profile ul.breadcrumb {
  padding: 10px 0px;
  list-style: none;
  /* background-color: #eee; */
}
.customer_profile ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  list-style: none;
}

.customer_profile ul i {
  color: #ffffff;
}
.customer_profile ul.breadcrumb li a {
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  text-decoration: none;
}
/* .customer_profile ul.breadcrumb li a:hover {
  color: #ffffff;
} */
.customer_profile ul.breadcrumb li a.active {
  color: #ffffff;
}
.customer_profile ul.breadcrumb i {
  color: rgba(153, 153, 153, 1);
  padding: 0 10px;
}

.customer_profile .profile_view {
  width: 100%;
  margin: auto;
  /* border: 1px solid white; */
  /* height: 303px; */
  background: #1f2737;
  border-radius: 8px;
  padding: 20px 30px;
}
.customer_profile .profile_view .profile_view_head {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customer_profile .profile_view .profile_img_left {
  width: 40%;
  display: flex;
  align-items: center;
}
.customer_profile .profile_view .profile_img {
  /* float: left; */
  /* width: 25%; */
  padding: 0px 8px 0 0;
}
.customer_profile .profile_name h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 14px;
  color: #ffffff;
  padding: 0 0 14px 0;
}
.customer_profile .profile_name h6 {
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: normal;
}

.customer_profile .profile_img_right {
  width: 60%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}
.customer_profile .kyc_status {
  width: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.customer_profile .kyc_status h6 {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
  padding: 0px 6px 0 0;
  font-weight: normal;
}
.customer_profile .kyc_status h3 {
  font-weight: 500;
  font-size: 16px;
  /* line-height: 14px; */
  color: #ffffff;
}

.customer_profile .profile_active_status {
  width: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 16px;
}
.customer_profile .profile_active_status .circle {
  font-size: 8px;
  border-radius: 50%;
  background: #2ecc71;
}
.customer_profile .profile_active_status h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #ffffff;
}
.customer_profile .profile_remove_button {
  width: 30%;
}

.customer_profile .profile_remove_button button {
  width: 100%;
  background: #2f394e;
  color: white;
  padding: 15px 40px;
  border-radius: 10px;
  border: none;
  display: flex;
  font-size: 15px;
  justify-content: center;
}
.customer_profile .profile_block_button {
  width: 30%;
}
.customer_profile .profile_block_button button {
  width: 100%;
  background: #3373ef;
  color: white;
  padding: 15px 40px;
  margin: 0 0 0 10px;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.customer_profile .profile_body {
  width: 79%;
  margin: auto;
  padding: 10px 0 0 0;
}

.customer_profile .profile_details_1,
.profile_details_2 {
  width: 100%;
  /* background-color: antiquewhite; */
  /* border: 1px solid #ffffff; */
  height: auto;
  display: flex;
  flex-direction: row;
  margin: 0px 0;
}
.customer_profile .joining_date,
.contact_number,
.email_id,
.rtc_balance,
.rto_balance,
.location {
  width: 25%;
  /* height: 10vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.customer_profile .joining_date h6,
.contact_number h6,
.email_id h6,
.rtc_balance h6,
.rto_balance h6,
.location h6 {
  font-size: 12px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.6);
}

.customer_profile .joining_date h1,
.contact_number h1,
.email_id h1,
.rtc_balance h1,
.rto_balance h1,
.location h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.customer_profile .customer_document {
  width: 314px;
  /* height: 250px; */
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
}

.customer_profile .document_img_card {
  width: 314px;
  height: 188px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 12px;
}
.document_container {
  margin-bottom: 25px;
}
.document_container .document_title h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  padding: 5px 0;
  margin: 25px 0 15px 0;
}

.customer_document .document_select_button {
  width: 316px;
  height: 49px;
  background: #1f2737;
  border-radius: 8px;
  position: absolute;
  top: 77%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.customer_document .doc_img {
  width: 15%;
}
.customer_document .doc_img img {
  width: 100%;
  float: left;
  padding: 0 10px;
}
.customer_document .doc_name {
  width: 75%;
}
.customer_document h1 {
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.customer_document .doc_option {
  width: 10%;
}
.customer_document .doc_option i {
  color: rgba(255, 255, 255, 1);
}

/* .customer_table tr td:nth-child(2) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 123px;
  float: left;
  padding: 22px 8px;
} */
.customer_table tr td:last-child {
  padding-right: 40px;
  width: 0px;
}

/* POPUP */

.popup {
  width: 401px;
  height: 248px;
  background: #1f2737;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.popup_title h1 {
  width: 85%;
  margin: auto;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
}

.popup_btn button {
  width: 121px;
  height: 48px;
  left: 70px;
  top: 160px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1f2737;
  color: #999999;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
  margin: 0 10px;
}
.popup_btn button.active {
  border: 1px solid #ffffff;
  color: #ffffff;
}

/*sub-admin- profile  */
.create_sub_admin_btn {
  background: #3373ef;
  border-radius: 9px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 15px 51px;
  border: none;
}
/*sub-admin- profile  */
.sub_admin_profile {
  width: 95%;
  margin: auto;
  /* / height: 100vh; / */
}
.sub_admin_profile .profile_view .profile_img img {
  max-width: 75px;
  width: 100%;
  float: left;
}
.sub_admincards input {
  color: white;
}
.sub_admincards .subfield2 {
  display: flex;
  padding-left: 2rem;
  width: 90%;
  /* justify-content: space-between; */
}
.sub_admin_profile .breadcrumb_head {
  width: 100%;
  padding: 15px 0 10px 0;
}

.sub_admin_profile ul.breadcrumb {
  padding: 10px 0px;
  list-style: none;
}

.sub_admin_profile ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  list-style: none;
}

.sub_admin_profile ul i {
  color: #ffffff;
}

.sub_admin_profile ul.breadcrumb li a {
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  text-decoration: none;
}
.sub_admin_profile ul.breadcrumb li a.active {
  color: #ffffff;
}

.sub_admin_profile ul.breadcrumb i {
  color: rgba(153, 153, 153, 1);
  padding: 0 10px;
}

.sub_admin_profile h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: 12px 0 21px 0;
}
.sub_admin_profile .profile_view {
  position: relative;
  width: 100%;
  margin: auto;
  background: #141b29;
  border-radius: 8px;
  padding: 40px 30px;
  float: left;
}
.sub_admin_profile .profile_view .profile_img {
  float: left;
  width: 10%;
  display: flex;
  justify-content: center;
  padding: 0 10px 0 0;
}
.sub_admin_profile .profile_edit_button {
  width: 100%;
  margin-top: 12px;
}

.sub_admin_profile .profile_edit_button button {
  width: 75%;
  background: #2f394e;
  color: white;
  padding: 15px 40px;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.sub_admin_profile .profile_remove_button {
  width: 100%;
}

.sub_admin_profile .profile_remove_button button {
  width: 75%;
  background: #141b29;
  color: white;
  padding: 15px 40px;
  border-radius: 10px;
  border: 1px solid #222a35;
  display: flex;
  font-size: 15px;
  justify-content: center;
  margin: 16px 0 0 0;
}

.sub_admin_profile .profile_body {
  width: 70%;
  margin-bottom: 100px;
  float: left;
  padding: 10px 0 0 0;
  position: relative;
}
.sub_admin_profile .profile_body select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../assets/images/Common/arrowDown.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 13px;
  width: 100%;
  height: 42px;
  background-color: #202633;
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  color: white;
  margin: 10px 0 12px 0;
  padding: 10px 10px 12px 10px;
  cursor: pointer;
}
.sub_admin_profile .profile_details_1 {
  margin-bottom: 35px;
  justify-content: space-between;
}
.sub_admin_profile .profile_details_2 {
  margin: 0px 0 10px 0;
}
.sub_admin_profile .profile_details_1,
.sub_admin_profile .profile_details_2 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sub_admin_profile .admin_name,
.admin_id,
.access,
.email_id,
.contact_no,
.location {
  width: 25%;
  /* height: 10vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.sub_admin_profile .admin_name h6,
.admin_id h6,
.access h6,
.email_id h6,
.contact_no h6,
.location h6 {
  font-size: 14px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.6);
}

.sub_admin_profile .admin_name h1,
.admin_id h1,
.access h1,
.email_id h1,
.contact_no h1,
.location h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.sub_admin_profile .sub_admin_profile_btn {
  width: 20%;
  float: left;
  position: absolute;
  right: 0%;
  top: 17%;
}

.sub_admin_profile .confirm_btn_parent {
  width: 100%;
  float: left;
  position: absolute;
}

.sub_admin_profile button.confirm {
  padding: 12px 74px;
  background: #3373ef;
  border-radius: 9px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 30px 0 20px 0;
}

.sub_admin_validate_msg {
  color: #ff5555;
  font-size: 14px;
  position: absolute;
  padding-top: 7px;
}
.sub_admin_validate_bg {
  background: #231619;
  border: 1px solid #ff5555;
}

.profile_body input[type="text"],
.profile_body input[type="number"] {
  width: 100%;
  height: 42px;
  background: #202633;
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  color: white;
  margin: 10px 0 12px 0;
  padding: 10px 10px 12px 10px;
}
.access details {
  color: white;
  /* / margin: 14px 0 14px 0; / */
  margin: 12% 0 18% 0;
}
details > summary {
  list-style: none;
  cursor: pointer;
}
details > summary::-webkit-details-marker {
  display: none;
}
details[open] {
  margin: 7.5% 0 7.5% 0;
}
details[open] > summary {
  display: none;
}
.profile_body input:checked summary {
  display: block;
}

/* / Chrome, Safari, Edge, Opera / */
.profile_body input::-webkit-outer-spin-button,
.profile_body input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* / Firefox / */
.profile_body input[type="number"] {
  -moz-appearance: textfield;
}

.disablecls {
  cursor: not-allowed !important;
  background-color: #131d30 !important;
  color: #99999957 !important;
}
.disablecls i {
  pointer-events: none;
}

.range_btn_input {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 133.5%;
  width: 263px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  background: transparent;
  margin-top: 8px;
  color: #ffffff;
  text-align: start;
  padding-left: 1rem;
  padding: 10px;
  border: 1px solid;
}
/********filter-end******/
.sub_admincards {
  width: 95%;
  margin: auto;
  background: #141b29;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.subadmin_head h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: 3%;
}

.sub_admincards .subfield {
  display: flex;
  padding: 2rem;
  justify-content: space-between;
  width: 90%;
}

.sub_admincards .subinput1 {
  width: 100%;
  height: 48px;
  background: #202633;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  color: white;
}

.sub_admincards .subfield2 {
  display: flex;
  padding: 0 2rem 0 2rem;
  width: 90%;
}

.sub_admincards .subinput2 {
  width: 100%;
  height: 48px;
  background: #202633;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  color: white;
}

.sub_admincards label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;

  color: #ffffff;
}

.sub_admincards .sub_para {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  /* identical to box height, or 29px */

  color: #ffffff;
  padding-top: 1rem;
  padding-left: 2rem;
}

.sub_admincards .sub_cards1 {
  width: 340px;

  background: #202633;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
}

.sub_admincards .sub_cards2 {
  width: 340px;

  background: #202633;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  margin-left: 5%;
}

.sub_cards {
  display: flex;

  margin-right: 16rem;
  padding: 2rem;
}

.sub_admincards .subradio {
  padding: 1rem;
}

.sub_admincards .input_div {
  width: 31%;
}

.sub_admincards .input_div1 {
  width: 31%;
}

.sub_admincards .input_div2 {
  margin-left: 3%;
  width: 31%;
}

.sub_admincards .subadmin_btn {
  width: 250px;
  height: 55px;
  background: #2f394e;
  border-radius: 9px;
  border: none;
  margin-bottom: 5rem;
  margin-left: 3%;
}

.sub_admincards .subbtn_text {
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.sub_admincards input {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  color: #6d6d6d;
}

.sub_admincards input[type="text"],
input[type="password"],
input[type="email"]::placeholder {
  padding-left: 10px;
}
.sub_admincards .permission_para {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.8);
  padding: 0 1rem;
}

.sub_admincards .sub_checkbox {
  padding: 1rem;
}

.sub_admincards .sub_checkbox label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}

.breadcrumb_head {
  width: 100%;
  padding: 25px 0px 10px 3%;
}

.breadcrumb {
  list-style: none;
  /* background-color: #eee; */
}

.breadcrumb li {
  display: inline;
  font-size: 18px;
  list-style: none;
}

ul i {
  color: #ffffff;
}

ul.breadcrumb li a {
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  text-decoration: none;
}

/* .sub_admin_profile ul.breadcrumb li a:hover {
    color: #ffffff;
  } */
ul.breadcrumb li a.active {
  color: #ffffff;
}

ul.breadcrumb i {
  color: rgba(153, 153, 153, 1);
  padding: 0 10px;
}

.sub_icon i {
  position: absolute;
  right: 14px;
  bottom: 16px;
  color: #6d6d6d;
}

.input_div {
  position: relative;
}

/* Radio Button */

.sub_admincards input[type="radio"] {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 0 2px gray;
}

.sub_admincards input[type="radio"]:checked {
  box-shadow: 0 0 0 2px #3373ef;
}

.sub_admincards input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

.sub_admincards input[type="radio"]:checked:before {
  background: #3373ef;
}

.sub_admincards .role {
  margin-left: 10px;
  font-weight: normal;
}

.sub_admincards .checkbox label {
  margin-bottom: 20px !important;
}

.sub_admincards .roles {
  margin-bottom: 40px;
}

/* cutomer profile view */
.customer_profile_view {
  width: 95%;
  margin: auto;
  /* height: 100vh; */
}

.customer_profile_view .breadcrumb_head {
  width: 100%;
  padding: 15px 0 10px 0;
}

.customer_profile_view ul.breadcrumb {
  padding: 10px 0px;
  list-style: none;
}

.customer_profile_view ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  list-style: none;
}

.customer_profile_view ul i {
  color: #ffffff;
}

.customer_profile_view ul.breadcrumb li a {
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  text-decoration: none;
}
.customer_profile_view ul.breadcrumb li a.active {
  color: #ffffff;
}

.customer_profile_view ul.breadcrumb i {
  color: rgba(153, 153, 153, 1);
  padding: 0 10px;
}

.customer_profile_view .profile_body h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: 12px 0 21px 0;
}
.customer_profile_view .profile_view {
  position: relative;
  width: 100%;
  margin: auto;
  background: #141b29;
  border-radius: 8px;
  padding: 40px 30px;
  float: left;
}
.customer_profile_view .profile_view .profile_img img {
  width: 100%;
  float: left;
  max-width: 75px;
}

.customer_profile_view .profile_view .profile_img {
  display: flex;
  float: left;
  justify-content: center;
  padding: 0px 10px 0px 0;
  width: 10%;
}

.customer_profile_view .profile_kyc_approved_button {
  width: 100%;
}

.customer_profile_view .profile_kyc_approved_button button {
  width: 75%;
  background: #3373ef;
  color: white;
  padding: 15px 4px;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.customer_profile_view .profile_block_button {
  width: 100%;
}

.customer_profile_view .profile_block_button button {
  width: 75%;
  background: #3373ef;
  color: white;
  padding: 15px 40px;
  border-radius: 10px;
  border: none;
  margin: 16px 0 0 0;
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.customer_profile_view .profile_remove_button {
  width: 100%;
}

.customer_profile_view .profile_remove_button button {
  width: 75%;
  background: #2f394e;
  color: white;
  padding: 15px 40px;
  border-radius: 10px;
  border: 1px solid #222a35;
  display: flex;
  font-size: 15px;
  justify-content: center;
  margin: 16px 0 0 0;
}

.customer_profile_view .profile_body {
  width: 70%;
  float: left;
  padding: 10px 0 0 0;
  position: relative;
}
.customer_profile_view .profile_details_1 {
  margin-bottom: 20px;
}
.customer_profile_view .profile_details_2 {
  margin: 0px 0 20px 0;
}
.customer_profile_view .profile_details_3 {
  margin: 0px 0 10px 0;
}
.customer_profile_view .profile_details_1,
.profile_details_2,
.profile_details_3 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  /* justify-content: space-; */
}

.customer_profile_view .customer_name,
.joining_date,
.email_id,
.contact_number,
.status,
.kyc_status,
.rtc_balance,
.rto_balance {
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.customer_profile_view .customer_name h6,
.joining_date h6,
.email_id h6,
.contact_number h6,
.status h6,
.kyc_status h6,
.rtc_balance h6,
.rto_balance h6 {
  font-size: 12px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.6);
}

.customer_profile_view .customer_name h1,
.joining_date h1,
.email_id h1,
.contact_number h1,
.status h1,
.kyc_status h1,
.rtc_balance h1,
.rto_balance h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.customer_profile_view .customer_profile_btn {
  width: 20%;
  float: left;
  position: absolute;
  right: 0%;
  top: 32%;
}

.customer_profile_view .confirm_btn_parent {
  width: 100%;
  float: left;
  position: absolute;
}

.customer_profile_view .customer_document {
  width: 100%;
  float: left;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 43px 0 0 0;
}
/* .customer_profile_view .customer_document h1{
  margin-bottom: 20px;
} */
.customer_document .cards {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.customer_document .document_card {
  position: relative;
  width: 33%;
  margin-bottom: 30px;
}
.customer_profile_view .document_img_card {
  /* width: 314px; */
  width: 90%;
  margin: auto;
  height: 188px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 12px;
}

.customer_profile_view .customer_document h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 5px 0;
}
.document_kyc_video video {
  border-radius: 10px;
}
.customer_profile_view .customer_document .document_select_button {
  /* width: 314px; */
  width: 90%;
  left: 5%;
  height: 45px;
  background: #1f2737;
  border-radius: 8px;
  position: absolute;
  top: 77%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.customer_document > h1 {
  margin-bottom: 20px;
}
.customer_document .document_card:first-child .document_img_card {
  margin: auto auto auto 0;
}
.customer_document .document_card:first-child .document_select_button {
  left: 0%;
}
.customer_document .doc_img {
  width: 15%;
}
.customer_document .doc_img img {
  width: 100%;
  float: left;
  padding: 0 10px;
}
.customer_document .doc_name {
  width: 75%;
}
.customer_document h1 {
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.customer_document .doc_option {
  width: 10%;
}
.customer_document .doc_option i {
  color: rgba(255, 255, 255, 1);
}

.customer_document .document_card .document_img_card img {
  width: 100%;
  height: auto;
  max-height: 183px;
}
.customer_profile_view .profile_details_1,
.customer_profile_view .profile_details_2,
.customer_profile_view .profile_details_3 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.customer_table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #233f78;
}
.customer_table::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}
.customer_table::-webkit-scrollbar-thumb {
  height: 8px;
  background-color: #6080bc;
  border-radius: 10px;
}
/* / Custom Checkbox / */

.chackbox-Div {
  position: relative;
}

.chackbox-Div .checkboxLabel {
  border: 1px solid #3f4654;
  border-radius: 15%;
  cursor: pointer;
  height: 13px;
  width: 13px;
  position: absolute;
  top: 8px;
  left: 0px;
}

.chackbox-Div .checkboxLabel:after {
  border: 2px solid;
  border-top: none;
  border-right: none;
  content: "";
  height: 3px;
  left: 1px;
  opacity: 0;
  position: absolute;
  top: 2px;
  transform: rotate(-45deg);
  width: 8px;
  color: #202633;
}

.chackbox-Div input[type="checkbox"] {
  visibility: hidden;
}

.chackbox-Div input[type="checkbox"]:checked + .checkboxLabel {
  background: #3373ef;
  border-color: #3373ef;
}

.chackbox-Div input[type="checkbox"]:checked + .checkboxLabel:after {
  opacity: 1;
}

.txtLapel {
  margin-left: 7px;
}

.customer_modal .head_filter {
  width: 87%;
  margin: auto;
}

.filter_name {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}
.head_filter .clr_top {
  cursor: pointer;
}
.head_filter .clr_top h1 {
  font-size: 14px;
}
.tops .filter-input h1.date {
  font-size: 14px;
  padding-top: 10px;
}
.filter_logo {
  display: flex;
  align-items: center;
}
.customer_modal .input2 {
  cursor: pointer;
}
.filter_text1 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  margin-left: 1rem;
  color: #ffffff;
}

.filter_textclr {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-left: 1rem;
  color: #ffffff;
}
.filter_textclr:hover {
  background: #434c5e;
  border: 1px solid;
  border-radius: 5px;
  left: 206px;
  margin: 0;
  padding: 12px;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 87px;
}

.filtertop_logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;
}

/* filter slider */
/* Slider Css styles*/

.css-eg0mwd-MuiSlider-thumb {
  border: 2px solid white;
  width: 15px !important;
  height: 15px !important;
}
.css-eg0mwd-MuiSlider-thumb:first-child {
  border: 2px solid white;
  width: 15px !important;
  height: 15px !important;
}

.css-187mznn-MuiSlider-root {
  height: 6px !important;
}

.sliderValue {
  display: flex;
  color: white;
  justify-content: space-between;
}

.slidecontainer {
  padding-bottom: 1rem;
}
.desktop-date {
  display: flex;
}
.date-input {
  width: 50%;
}
.date-input label {
  margin-bottom: 5px;
}
.date-input input {
  width: 100%;
  height: 48px;
  text-align: center;
  background: #1f2737;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  outline: none;
  border-radius: 10px;
  padding: 12px 8px;
  margin-top: 5px;
  color: white !important;
}
.filer_bottom_btn {
  color: white;
  background: #1f2737;
  padding: 5%;
  margin: 0 0 21px 0;
  border: 1px solid;
  border-radius: 5px;
  width: 40%;
}
.filer_bottom_btn:hover {
  background: #434c5e;
}
.disable-chackbox label.txtLapel {
  color: #3f4654;
}

.disable-chackbox input[type="checkbox"]:checked + .checkboxLabel {
  background: #3f4654;
  border-color: #3f4654;
}

.css-14pt78w-MuiSlider-rail {
  opacity: 1;
  color: #353d4b;
}

.filter_div {
  display: flex;
  justify-content: center;
}
.subfield_password_validation {
  padding-left: 2rem;
  padding-top: 2.5rem;
  width: 90%;
}

.subfield_password_validation p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #ffffff6b;
}

.table_options {
  background: #1f2737;
  border-radius: 10px;
  position: absolute;
  right: 48px;
  top: 43px;
  /* width: 146px; */
  z-index: 10;
}

.customer_table tr:last-child .table_options,
.customer_table tr:nth-last-child(2) .table_options,
.customer_table tr:nth-last-child(3) .table_options {
  background: #1f2737;
  border-radius: 10px;
  position: absolute;
  right: 48px;
  top: -90px;
  /* width: 146px; */
  z-index: 10;
}

.customer_table table tr:nth-child(1) .table_options,
.customer_table table tr:nth-child(2) .table_options,
.customer_table table tr:nth-child(3) .table_options,
.customer_table table tr:nth-child(4) .table_options {
  background: #1f2737;
  border-radius: 10px;
  position: absolute;
  right: 48px;
  top: 43px;
  /* width: 146px; */
  z-index: 10;
}

.table_options ul {
  padding: 15px 0px 20px 20px;
}
.table_options ul li {
  list-style-type: none;
  font-size: 12px;
  padding-bottom: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.7);
}
.table_options ul li a {
  color: rgba(255, 255, 255, 0.7);
}
.table_options ul li:hover a {
  color: white;
}
input.form-control.phone_input {
  width: 100%;
  height: 48px;
  background: #202633;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
}
.flag-dropdown.phone_input_button {
  background: #202633 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: #202633 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

ul.country-list.phone_input_dropdown {
  background: #202633 !important;
  color: white !important;
}
li.search.phone_input_search {
  background: #161e2f !important;
}
input.search-box.phone_input_search-box {
  background: #202633 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
ul.country-list.phone_input_dropdown li:hover {
  background: #161e2f !important;
}
ul.country-list.phone_input_dropdown li[aria-selected="true"] {
  background: #3373ef !important;
}
ul.country-list.phone_input_dropdown li[aria-selected="true"] span.dial-code {
  color: lightgrey !important;
}
.selected-flag.open {
  background: #202633 !important;
}
ul.country-list.phone_input_dropdown
/* 
/ //scroll bar / */


ul.country-list.phone_input_dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #233f78 !important;
}
ul.country-list.phone_input_dropdown::-webkit-scrollbar {
  width: 8px;
  background-color: #161e2f !important;
}
ul.country-list.phone_input_dropdown::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #6080bc !important;
  border-radius: 10px;
}
input.form-control.phone_input:focus {
  background: #202633;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  outline: none;
  box-shadow: none;
}

.flag-dropdown.phone_input_button {
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
input.PhoneInputInput {
  padding-left: 10px;
  width: 100%;
  height: 48px;
  background: #202633;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
  padding: 5px;
}
input.PhoneInputInput:focus {
  outline: none;
  border: none;
}
select.PhoneInputCountrySelect {
  width: 100%;
  height: 42px;
  background: #202633;
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  color: #ffffff6b;
  padding: unset;
  margin: unset;
  cursor: pointer;
}

.PhoneInputCountry {
  color: white;
}

select.PhoneInputCountrySelect::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #233f78 !important;
}
select.PhoneInputCountrySelect::-webkit-scrollbar {
  width: 8px;
  background-color: #161e2f !important;
}
select.PhoneInputCountrySelect::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #6080bc !important;
  border-radius: 10px;
}

.PhoneInput {
  display: flex;
  align-items: center;
  background: #202633;
  border-radius: 10px;
}

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-left: var(--PhoneInputCountrySelect-marginRight);
}
.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
}

.toggle_list {
  position: relative;
  width: 88%;
  margin: 0 5% 0 7%;
  cursor: pointer;
  color: #ffffff6b;
  list-style-type: none;
}
.list.list_active + .toggle_unordered_list {
  display: block;
  margin-bottom: -5px;
  margin-top: 22px;
}
.toggle_unordered_list {
  display: none;
}
.list.list_active {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 15px 0 15px 13px;
  margin: -15px 13px -11px -10px;
}
.list.list_active span.span_text.arrow {
  padding-right: 15px;
}
.toggle_list_active {
  background: #3373ef;
  border-radius: 8px;
}

.toggle_list_active a {
  color: white;
}
.list.list_active .span_icon {
  color: white;
}
.list.list_active .span_text a {
  color: white;
}

i.far.fa-chevron-up {
  color: white !important;
}
.list.list_active .span_icon svg path {
  fill-opacity: 1;
  fill: #fff;
}

/* / Admin Wallet Settings / */

/*********customer_content*******/
.customer_content .both_button {
  display: flex;
  margin-left: 1rem;
}

.customer_content h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
}

.customer_content .dash_input {
  width: 100%;
  height: 48px;
  background: #141b29;
  border: 1px solid #273148;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
}

.customer_content .search_icon {
  position: relative;
  left: 35px;
  top: 13px;
  font-size: 20px;
  color: #999999;
}

.customer_content .filter_btn {
  width: 100px;
  height: 48px;
  background: #161e2f;
  border-radius: 10px;
  color: #ffffff;
  margin-left: 1rem;
  outline: none;
  border: none;
  padding: 10px 16px;
}

.customer_content input,
select,
textarea {
  color: white;
  padding: 23px 45px;
  font-size: 19px;
}

.customer_content .filter_icons {
  height: 16px;
  width: 16px;
  color: #ffffff;
}

.customer_content .filter_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.customer_table th {
  padding: 16px;
  /* width: 100px; */
}

.customer_table td {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  padding: 1rem;
}

.customer_table .th {
  background-color: #1f2737;
}

.customer_table th {
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  opacity: 0.6;
}

.customer_table {
  margin: auto;
  border-collapse: collapse;
  font-size: 0.9em;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  width: 95%;
  background-color: #141b29;
  overflow-x: auto;
  min-height: 472px;
}

.customer_table .Inprogress {
  color: #559bff;
}

.customer_table .Incomplete {
  color: #ff8836;
}

.customer_table .inactive {
  color: rgba(255, 255, 255, 0.3);
}

.customer_table .complete {
  color: #2ecc71;
}

.customer_page .pagination {
  display: inline-block;
}

.customer_page .pagination a {
  color: #999999;
  float: left;
  padding: 8px 15px;
  text-decoration: none;
  background-color: #1f2737;
  border-radius: 5px;
  margin: 1rem 0rem 1rem 1rem;
}

.customer_page .pagination a.active {
  color: #ffffff;
  border-radius: 5px;
}

.customer_page .result p {
  color: #999999;
  font-size: 14px;
  margin: 1rem 0rem 1rem 1rem;
}

.customer_page .current_page {
  color: #ffffff;
}

.customer_table .bars {
  position: relative;
  left: 10px;
  top: 2px;
  color: #ffffff;
  font-weight: 400;
  font-size: 22px;
}

.customer_table .inactive_bars {
  position: relative;
  left: 5px;
  top: 2px;
  font-weight: 400;
  font-size: 22px;
  color: #ffffff;
}

.customer_table .dot {
  font-size: 9px;
  position: relative;
  color: #2ecc71;
  top: -2px;
  left: -5px;
}

.customer_table .inactive_alert {
  font-size: 9px;
  position: relative;
  color: #5b5f69;
  top: -2px;
  left: -3px;
}

.modal {
  background: none !important;
  float: right;
  position: relative;
  z-index: 2;
  max-width: 305px !important;
  box-sizing: border-box;
  width: 90%;
  padding: 0px 0px;
  border-radius: 8px;
  padding: 0px 30px !important;
  margin-right: 100px;
}

.customer_modal .cards {
  width: 318px;
  background: #1f2737;
  border-radius: 10px;
}

.customer_modal .date {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.customer_modal .filter-input {
  width: 85%;
  margin: auto;
  padding-top: 45px;
  height: 11.5rem;
}

.customer_modal .lines {
  position: relative;
  border: none;
  height: 1px;
  background: rgba(219, 196, 196, 0.1);
  width: 100%;
}

.customer_modal .bothfield {
  display: flex;
  padding-top: 50px;
  column-gap: 34px;
  padding-bottom: 15px;
}

.customer_modal .input1 {
  width: 7rem;
  height: 37px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  background: transparent;
}

.customer_modal label {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 14px;
  color: #ffffff;
}

.customer_modal .rto-Spent {
  width: 85%;
  margin: auto;
  padding-top: 20px;
}

.customer_modal .btns {
  padding-top: 10px;
}

.customer_modal .btns_clr {
  margin-left: 5rem;
  margin-top: 2rem;
}

.customer_modal .rto-spent li {
  color: #ffffff;
  list-style: none;
  display: inline;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 6px;
}

.customer_modal .below {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 20px;
}

.customer_modal .input2 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 133.5%;
  width: 263px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  background: transparent;
  color: #ffffff;
  text-align: start;
  padding: 13px;
}

.customer_modal input,
select,
textarea {
  color: white;
  padding: 10px;
  font-size: 15px;
}

.customer_modal .clr {
  width: 86px;
  height: 36px;
  background: transparent;
  border: 1px solid #3373ef;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #3373ef;
}

.customer_modal .slider {
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
}

.customer_modal .slider:hover {
  opacity: 1;
}

.customer_modal .range {
  color: white;
  font-size: 15px;
  width: 100%;
}

.customer_modal .active-spent {
  width: 80px;
  border-bottom: 1px solid white;
  padding: 11px;
  padding-left: 1px;
}

.wallet_cards {
  display: flex;
  margin-top: 2rem;
}

.card {
  width: 273px;
  background: #141b29;
  border-radius: 12px;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}

.cards3 {
  width: 175px;
  height: 48px;
  background: #3373ef;
  border-radius: 10px;
  margin: 1rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  cursor: pointer;
}

.subcards {
  background-color: #060d1b;
  width: 15%;
  margin: 1rem;
  padding: 9px;
  border-radius: 5.06876px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subcards img {
  width: 100%;
  float: left;
}
.para {
  color: #ffffff;
}

.wall {
  color: rgba(221, 221, 221, 0.8);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
}

.wallet {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: rgba(249, 249, 249, 0.8);
}
tbody tr td {
  color: white;
}

.wallet-filter {
  right: 21px !important;
  top: 395px !important;
}

/* wallaet Input */
/* Deposit card */

.deposit_body {
  display: flex;
  justify-content: center;
  padding: 50px 0 0 0;
}

.deposit_card {
  width: 401px;
  /* height: 248px; */
  padding: 60px 0;
  background: #141b29;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deposit_content {
  width: 70%;
  margin: auto;
}

.deposit_title h1 {
  /* width: 85%; */
  margin: auto;
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.deposit_input {
  position: relative;
}

.deposit_input input {
  width: 96%;
  height: 46px;
  margin: 17px 0 14px 0;
  background: #202633;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  padding: 0 0 0 15px;
  color: #ffffff;
}

.deposit_input ::placeholder {
  font-size: 16px;
}

.deposit_input h1 {
  position: absolute;
  top: 29px;
  right: 30px;
  color: #ffffff;
  font-weight: 100;
  font-size: 18px;
}

.deposit_value h3 {
  font-weight: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #999999;
  padding: 10px 0;
}

.deposit_value span {
  color: #ffffff;
  font-weight: 400;
  padding: 0 0 0 7px;
}

.deposit_btn {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.deposit_btn button {
  width: 220px;
  padding: 15px 20px;
  background: #2f394e;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  color: #ffffff;
}

.toggle_unordered_list .toggle_list_active a {
  color: #ffffff;
}

.wallet_cards + .customer_content .both_button .customer_modal .below {
  margin-top: unset;
}

/* /////new changes//// */
/* / Deposit card / */

.deposit_body {
  display: flex;
  justify-content: center;
  padding: 50px 0 0 0;
}

.deposit_card {
  width: 472px;
  padding: 72px 0;
  background: #141b29;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.deposit_content {
  width: 70%;
  margin: auto;
}

.deposit_title h1 {
  margin: auto;
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding-bottom: 10px;
}

.deposit_input {
  position: relative;
}

.deposit_input input {
  width: 96%;
  height: 46px;
  margin: 17px 0 17px 0;
  background: #202633;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  padding: 0 58px 0 15px;
  color: #ffffff;
  outline: none;
}

.deposit_input input:focus {
  border: 1px solid #273148;
}
.deposit_input ::placeholder {
  font-size: 16px;
}

.deposit_input h1 {
  position: absolute;
  top: 29px;
  right: 30px;
  color: #ffffff;
  font-weight: 100;
  font-size: 18px;
}

.deposit_value h3 {
  font-weight: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #999999;
  padding: 10px 0;
}

.deposit_value span {
  color: #ffffff;
  font-weight: 400;
  padding: 0 0 0 7px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.deposit_btn {
  display: flex;
  justify-content: center;
  margin: 45px 0 105px 0;
}

.deposit_btn button {
  width: 220px;
  padding: 15px 20px;
  background: #2f394e;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
}

.deposite_title h3 {
  color: white;
  font-weight: 500;
}

.deposite_title p {
  color: #999999;
  font-weight: 100;
  margin: 20px 0 20px;
}

.deposite_otp {
  font-size: 15px;
}

.mpi_verfication input[type="tel"] {
  border: none !important;
}

.deposite_otp {
  display: flex;
  justify-content: center;
}

.deposite_title p a {
  border-bottom: 1px solid;
}

/* / Suceess Page / */

/* .sucess_body {
  position: relative;
} */

.sucess_card {
  padding: 20px;
  background: #141b29;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dialog_box {
  border-radius: 10px;
}
.sucess_content {
  margin: auto;
}

.img_content img {
  display: block;
  width: 16%;
}

.img_content h2 {
  color: white;
  margin: 25px 0 25px 0;
  font-weight: 400;
  font-size: 18px;
}

.img_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sucess_table table {
  width: 100%;
  margin: 25px 0 25px 0;
  table-layout: fixed;
  padding: 0 25px;
}

.sucess_table table td {
  width: 50%;
  padding: 17px;
  color: white;
  font-size: 15px;
}

.sucess_table table td:first-child {
  color: #74777f;
  /* width: 70%; */
}

.sucess_button {
  margin: auto;
  display: flex;
  justify-content: center;
}

.sucess_button button {
  padding: 15px 80px;
  background: #3373ef;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 20px;
}
.sucess_table table td {
  padding: 17px;
  color: white;
  line-height: 20px;
}
.dropdown li a span {
  color: rgba(255, 255, 255, 0.7);
}
.dropdown li a:hover span {
  color: white;
}

.admin_wallet_cards {
  width: 95%;
  margin: auto;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.admin_wallet_cards .wallet_bal_card {
  background: #141b29;
  border-radius: 12px;
  width: 43%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.admin_wallet_cards .wallet_bal_card p {
  color: rgba(221, 221, 221, 0.8);
  font-size: 14px;
}

.admin_wallet_cards .wallet_bal_card p:last-child {
  color: rgb(221 221 221 / 38%) !important;
  font-size: 12px;
}
.admin_wallet_cards .wallet_bal_card h3 {
  color: white;
  font-size: 18px;
  padding: 8px 0;
  font-family: Inter;
  font-style: normal;
}
.admin_wallet_cards .wallet_deposit .wallet_deposit_btn {
  /* width: 175px; */
  height: 42px;
  background: #3373ef;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 1rem;
  font-size: 16px;
}
.admin_wallet_cards .total_lendings_card {
  background: #141b29;
  border-radius: 12px;
}
.admin_wallet_cards .total_lendings_card {
  width: 55%;
  padding: 2% 5%;
}
.admin_wallet_cards .total_lendings_card hr {
  border: 1px solid #222a35;
}
.total_lendings_card .total_lendings,
.total_lendings_card .total_amount_lent {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.total_lendings_card .total_amount_lent {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.total_lendings_card .total_lendings p,
.total_lendings_card .total_amount_lent p {
  color: rgba(221, 221, 221, 0.7);
  font-size: 14px;
}
.total_lendings_card .total_lendings h4,
.total_lendings_card .total_amount_lent h4 {
  color: rgba(221, 221, 221, 0.7);
  color: white;
  font-size: 16px;
}

.toggle_unordered_list .toggle_list a {
  padding: 15px 0 15px 20px;
  display: block;
}

.toggle_unordered_list .toggle_list span {
  padding: 15px 0 15px 20px;
  display: block;
}

.toggle_unordered_list .toggle_list_active span {
  color: #ffffff;
}

/* Wallet table */
.customer_modal .filter-input {
  width: 85%;
  margin: auto;
  padding-top: unset;
  height: unset;
}

.wallet-pop {
  padding-top: 0 !important;
}

/* Arrow */
.PhoneInputCountrySelectArrow::after {
  display: block;
  content: "";
  width: 0.3em;
  height: 0.3em;
  margin-left: 0.35em;
  border-style: solid;
  border-color: inherit;
  border-top-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 1px;
  opacity: 0.45;
  position: absolute;
  bottom: -1px;
  transform: rotate(360deg);
  left: -7px;
}

.mtpin_input_timer {
  justify-content: center;
  display: flex;
}
.mtpin_input_timer input[type="text"] {
  padding: 9px 0px;
  width: 90px;
  border-radius: 6px;
  border: none;
  text-align: center;
  background: rgb(32, 38, 51);
  color: white;
  height: 40px;
  font-size: 37px inherit;
  font-size: 16px;
  font-weight: 500;
}

.mtpin_otp_inputs_response {
  color: #ff5555;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.mpi_verfication input:not([value=""]) {
  border: 2px solid #ffffff !important;
}

.mpin_continue_btn {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.mpin_continue_btn button {
  width: 220px;
  padding: 15px 20px;
  background: #2f394e;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
}

p.deposit_input_error {
  color: #ff4f4f;
  position: absolute;
}

.react-loading {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
}

.react-loading-div {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(70 70 70 / 50%);
}

.customer_table .wallet_table th:first-child {
  padding: 20px 22px 20px 35px;
  width: 50%;
}

.mtpin_input_timer + .deposite_title p {
  text-align: center;
}

.mpi_verfication_danger input {
  border: 1px solid red !important;
}

.customer_modal.wallet-filter .below {
  margin-top: 15px;
}

.deposit_dialog_box div[aria-describedby="alert-dialog-description"] {
  border-radius: 10px !important;
  background-color: unset !important;
}

.graph_img {
  margin-left: -28px;
}

.breadcrumb_head {
  width: 100%;
  padding: 25px 0px 10px 0%;
  width: 95%;
  margin: auto;
}
/* MEDIA QUERIES */

@media screen and (min-width: 1650px) and (max-width: 2500px) {
  .sidebar_parent {
    width: 20%;
    float: left;
  }
  .body {
    width: 80%;
    float: left;
  }

  .body .stat_card .stat_text {
    padding: 13px 0 30px 0;
    position: relative;
  }
}

@media screen and (max-width: 1225px) {
  .body .stat_text h4 {
    font-size: 10px;
  }
  .body .stat_text h3 {
    font-size: 13px;
    line-height: 11px;
  }
}

@media screen and (max-height: 650px) {
  .img_content h2 {
    margin: 15px 0 15px 0;
  }

  .sucess_table table {
    margin: 15px 0 15px 0;
  }

  .sucess_table table td {
    padding: 12px;
  }
  .sucess_button button {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 2500px) {
  .admin_wallet_cards .wallet_bal_card {
    padding: 2rem;
  }
  .admin_wallet_cards .wallet_deposit .wallet_deposit_btn {
    padding: 1rem 2rem;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1250px) {
  .sidebar_parent .side_menu .list .span_text a {
    font-size: 14px;
  }
  .sidebar_parent .side_menu .list .span_text {
    font-size: 14px;
  }
  .toggle_unordered_list .toggle_list a {
    font-size: 14px;
  }
  .toggle_unordered_list .toggle_list span {
    font-size: 14px;
  }
}

@media screen and (min-width: 950px) and (max-width: 1100px) {
  .sidebar_parent .logo {
    margin: 40px 14% 0px 20%;
  }
  .sidebar_parent .logo img {
    width: 100%;
    float: left;
  }
  .sidebar_content ul a {
    font-size: 13px;
  }
  .sidebar_content ul span {
    font-size: 13px;
  }
  .sidebar_content ul .span_text {
    font-size: 13px;
  }
  .sidebar_parent .side_menu {
    padding: 12px 0 12px 20px;
  }
  .sidebar_parent .side_menu .list.active {
    padding: 12px 0 12px 13px;
  }
  .sidebar_parent span.span_text.arrow {
    padding: 0 9% 0 10px;
  }
  .list.list_active {
    padding: 12px 0 12px 13px;
  }

  .list.list_active .span_text.arrow {
    padding-right: 4% !important;
  }
  .span_icon svg {
    width: 95%;
  }
}

@media screen and (min-width: 400px) and (max-width: 949px) {
  .sidebar_parent .logo {
    margin: 40px 14% 0px 20%;
  }
  .sidebar_parent .logo img {
    width: 100%;
    float: left;
  }
  .sidebar_content ul a {
    font-size: 11px;
  }
  .sidebar_content ul span {
    font-size: 11px;
  }
  .sidebar_parent .side_menu {
    padding: 10px 0 10px 20px;
  }
  .sidebar_parent .side_menu .list.active {
    padding: 9px 0 9px 13px;
  }
  .nav_content h1 {
    font-size: 21px;
  }
  .sidebar_content ul .span_text {
    font-size: 11px;
  }
  .sidebar_parent span.span_text.arrow {
    padding: 0 9% 0 10px;
  }
  .list.list_active {
    padding: 9px 0 9px 13px;
  }
  .span_icon svg {
    width: 90%;
  }
  .list.list_active .span_text.arrow {
    padding-right: 4% !important;
  }
}

@media screen and (max-width: 1080px) {
  .stat_body_cards {
    width: 100%;
    float: left;
    display: block !important;
    justify-content: center;
  }
  .body .stat {
    width: 100%;
    padding: 10px;
  }
}

.customer_modal.wallet-filter .below + .btns {
  padding-top: unset;
  padding-bottom: 10px;
}

.customer_modal.wallet-filter .desktop-date {
  padding-bottom: 7px;
}

.customer_modal.wallet-filter .date-input input {
  height: 42px;
  border-radius: 6px;
}

.customer_modal.wallet-filter .tops .filter-input h1.date {
  padding-top: 8px;
}

.profile_details_2 .contact_no .PhoneInput {
  margin: 10px 0 12px;
}

.profile_details_2 .contact_no .PhoneInput input.PhoneInputInput {
  height: 42px;
}

.dropdown-analytics {
  width: 91px;
  height: 35px;
  background: #060d1b;
  border-radius: 4.44989px;
  position: relative;
  cursor: pointer;
}
.dropdown-analytics img {
  position: absolute;
  top: 15px;
  right: 10px;
}
.stat_graph_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown-analytics-ul {
  position: absolute;
  right: 20px;
  top: 60px;
  width: 91px;
  background: #060d1b;
  border-radius: 4.44989px;
  z-index: 999;
  padding: 5px 0;
  transition: all 1s;
}
.dropdown-analytics-ul li {
  list-style: none;
  color: #ffffff81;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 0 5px 10px;
  cursor: pointer;
}
.dropdown-analytics-ul li:hover {
  color: #ffffff;
}
.body .stat_graph {
  position: relative;
}
.dropdown-analytics span {
  position: absolute;
  top: 9px;
  left: 10px;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.table_options ul li:hover span {
  color: #ffffff;
}
.document_select_button {
  max-width: 314px;
  min-width: 250px;
}
.document_img_card {
  max-width: 314px;
  min-width: 250px;
}
.document_img_card img {
  border-radius: 12px;
}

.spinner-cls {
  height: 100vh;
  display: flex;
}
.sweet-loading {
  margin: auto;
}

/* Animation */
/* 
.nav_content .bell_icon {
  transform-origin: center top;
  animation: swing 1s;
}

.nav_content .prof {
  animation: profile 1s;
}

.nav_content .dropdown {
  animation: scrollAni 0.5s;
  overflow: hidden;
} */

/* Bell Swing */
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-45deg);
  }

  75% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

/* Profile Rotate */

@keyframes profile {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

/* Notifiaction Animation */

@keyframes scrollAni {
  0% {
    height: 10px;
  }
  100% {
    height: 149px;
  }
}

/* Animation */
