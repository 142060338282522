/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    /* -webkit-box-shadow: 0 0 0 30px #202633 inset !important; */
    transition: background-color 600000s 0s, color 600000s 0s;
}
/* input[data-autocompleted] {
    background-color: #202633 !important;
} */

/*Change text in autofill textbox*/
input:-webkit-autofill{
    -webkit-text-fill-color: white !important;
}